import { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const NavBar: FunctionComponent = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const storitve = [
    { title: 'Urejanje meje', link: '/urejanje-meje' },
    { title: 'Parcelacija', link: '/parcelacija' },
    { title: 'Geodetski načrt', link: '/geodetski-nacrt' },
    { title: 'Zakoličba objekta', link: '/zakolicba' },
    { title: 'Vpis stavbe', link: '/vpis-stavbe' },
  ];

  return (
    <>
      <nav
        className={`w-full ${
          sidebarOpen ? 'bg-white' : 'bg-neutral/900'
        }  z-50 fixed top-0`}
      >
        <div
          id="navbar"
          className={`container h-20 md:h-24 p-6 md:py-8 md:px-20 z-50 flex justify-between items-center transition ease-in-out mx-auto`}
        >
          {isDesktop ? (
            <div className="w-full flex flex-row items-center gap-8">
              <div>
                <h5 className="peer text-neutral/50 hover:text-white text-base font-bold cursor-pointer">
                  Naše storitve
                </h5>
                <div className="invisible peer-hover:visible hover:visible shadow-dropdown pt-6 absolute transition-opacity">
                  <div className="w-[200px] bg-white rounded py-4 flex flex-col text-base leading-7 text-neutral/900">
                    {storitve.map((storitev) => (
                      <Link to={storitev.link}>
                        <p className="py-4 px-6 hover:bg-neutral/50 hover:font-bold">
                          {storitev.title}
                        </p>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <h5 className="text-neutral/50 text-base font-bold">
                Naše stranke
              </h5>
            </div>
          ) : null}
          <Link to="/">
            <img
              src={
                sidebarOpen
                  ? '/home/geoprojekt-logo-dark.png'
                  : '/home/geoprojekt-logo.png'
              }
              alt="Geoprojekt"
              className="w-32 md:w-[200px] lg:w-[300px]"
            />
          </Link>
          {isDesktop ? (
            <div className="w-full flex flex-row items-center justify-end gap-8">
              <h5 className="text-neutral/50 text-base font-bold">O nas</h5>
            </div>
          ) : (
            <img
              src={sidebarOpen ? '/icons/close.svg' : '/icons/hamburger.svg'}
              onClick={() =>
                sidebarOpen ? setSidebarOpen(false) : setSidebarOpen(true)
              }
            />
          )}
        </div>

        {/* Sidebar */}
        {isDesktop ? null : (
          <div
            className={`fixed top-20 left-0 w-full h-full z-50 flex flex-col gap-2 p-6 bg-white transition ease-in-out ${
              sidebarOpen ? null : 'translate-x-full'
            }`}
          >
            <p className="font-lato font-bold w-full p-3">Naše storitve</p>

            {storitve.map((storitev) => (
              <Link
                to={storitev.link}
                className="font-lato font-bold w-full p-3 pl-8 rounded-lg hover:bg-neutral/50 hover:text-neutral/900"
              >
                {storitev.title}
              </Link>
            ))}

            <Link
              to=""
              className="font-lato font-bold w-full p-3 rounded-lg hover:bg-neutral/50 hover:text-neutral/900"
            >
              Naše stranke
            </Link>
            <Link
              to=""
              className="font-lato font-bold w-full p-3 rounded-lg hover:bg-neutral/50 hover:text-neutral/900"
            >
              O nas
            </Link>
          </div>
        )}
      </nav>
      {/* To prevent content going below navbar */}
      <div className="h-20 md:h-24 bg-neutral/900"></div>
    </>
  );
};

export default NavBar;
