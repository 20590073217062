import { Fragment, FunctionComponent } from 'react';
import ImageBulletSection from '../../components/ImageBulletSection';
import NavBar from '../../components/NavBar';
import ServiceHero from '../../components/ServiceHero';
import ServicesBottom from '../../components/ServicesBottom';
import ServicesExplanationSection from '../../components/ServicesExplanationSection';
import StepsSection from '../../components/StepsSection';
import WhatIfSection from '../../components/WhatIfSection';

export const UrejanjeMeje: FunctionComponent = () => {
  return (
    <Fragment>
      <NavBar />
      <ServiceHero
        title={'Urejanje meje'}
        subtitle={
          'Urejanje meje je postopek, ki ga potrebujete, ko želite izvedeti, kje so meje vaše parcele.'
        }
      />
      <ImageBulletSection
        imgSrc={'/home/service1.png'}
        title={'Kdaj potrebujete ureditev parcelne meje?'}
        bullets={[
          'Želite postaviti mejnike na meji vaše parcele?',
          'Ste kupili parcelo, pa ne veste kje se konča vaše in prične sosedovo?',
          'Želite izvesti parcelacijo, pa meje parcele še niso urejene?',
          'Želite s sosedom izravnati del meje, pa ta meja še ni urejena?',
          'Se pripravljate na gradnjo in vas zanima ali bo stala hiša dovolj daleč od meje s sosedom?',
          'Želita s sosedom postaviti ograjo na mejo, pa ne vesta kje le-ta poteka?',
          'Ste v sporu s sosedom, ker kosi vašo travo, seka vaš gozd ali postavlja ograjo na vašem zemljišču?',
          'Se pripravljate na prodajo parcele? Kupec bo zagotovo želel vedeti, do kod sega meja parcele, ki jo kupuje.',
        ]}
      />
      <StepsSection
        title={'Kako poteka postopek ureditve meje?'}
        subtitle={'Ureditev meje poteka v dveh ločenih fazah.'}
        steps={[
          'V prvi fazi geodet z geodetsko izkaznico, geodetskega podjetja z dovoljenjem za izvajanje geodetskih storitev, izvede urejanje meje.',
          'V drugi fazi pa Geodetska uprava RS izvede evidentiranje te meje v zemljiški kataster.',
        ]}
      />
      <ServicesExplanationSection
        title="Geodetovo delo"
        items={[
          {
            title: 'Vabila na meritev',
            content: (
              <p>
                Geodet mora vabiti vse vpletene na meritev preko pošte. Vabila
                na meritev mora poslati vsaj 8 dni pred meritvijo. Vabil ni
                potrebno poslati s povratnico, pač pa priporočeno. Od pošte
                geodet potrebuje jasno dokazilo, kdaj je bila pošta poslana, za
                kaktero zadevo in datum meritve – potrjen poštna knjiga. Poštna
                knjiga je sestavni del elaborata urejanja mej.
              </p>
            ),
          },
          {
            title: 'Predhodne meritve',
            content: (
              <p>
                Pred mejno obravnavo lahko geodet opravi predhodne meritve. Te
                opravi brez prisotnosti lastnikov vpletenih parcel. Predhodne
                meritve so namenje boljši pripravi geodeta na mejno obravnavo. S
                pomočjo predhodnih meritev in podatkov, ki jih dobi na Geodetski
                upravi RS (arhivske meritve), si geodet ustvari realno sliko o
                poteku mej in o natančnosti arhivskih meritev.
              </p>
            ),
          },
          {
            title: 'Mejna Obravnava',
            content: (
              <p>
                Geodet najprej preveri prisotnost vseh vpletenih in njihovo
                identiteto. Vsakemu lastniku vpletenih parcel da možnost, da
                pokaže svojo mejo. Na koncu predlaga mejo po podatkih
                zemljiškega katastra in na željo lastnikov postavi mejna
                znamenja. Ves čas postopka geodet/zapisnikar piše zapisnik mejne
                obravnave, ki ga geodet na koncu prebere in ga podpišejo vsi
                prisotni lastniki vpletenih parcel.
              </p>
            ),
          },
          {
            title: 'Izdelava elaborata urejanja mej',
            content: (
              <p>
                Z vsemi podatki, ki jih je zbral na mejni obravnavi, geodet v
                pisarni naredi elaborat urejanja mej. Ključne sestavine takega
                elaborata so:
                <ul className="list-disc list-inside mt-2 mb-4">
                  <li>skica meritve,</li>
                  <li>prikaz sprememb poteka mej,</li>
                  <li>izračun površin parcel</li>
                  <li>
                    navedba dokumenta, na podlagi katerega je bila izvedena
                    parcelacija oziroma navedba, da je bila parcelacija izvedena
                    skladno z željami stranke.
                  </li>
                </ul>
                S tem je geodetovo delo zaključeno in prične se druga faza -
                <b> evidentiranje urejene meje</b>, ki se vrši na Geodetski
                upravi RS.
              </p>
            ),
          },
        ]}
      />
      <ServicesExplanationSection
        title="Evidentiranje urejene meje"
        items={[
          {
            title:
              'Predaja zahtevka za uvedbo postopka evidentiranja urejene meje',
            content: (
              <p>
                Zahtevek za uvedbo postopka evidentiranja urejene meje, skupaj z
                elaboratom urejanja mej, lastnik katerekoli vpletene parcele,
                preda na Geodetsko upravo RS. Na Geodetski upravi RS mora
                vlagatelj plačati upravno takso, ki znaša 22,66 €. To lahko
                namest njega naredi tudi geodet, vendar potrebuje lastnikovo
                pooblastilo, takso pa doda kot strošek na računu za storitev
                urejanja mej.
              </p>
            ),
          },
          {
            title: 'Evidentiranje urejene meje',
            content: (
              <p>
                Če se lastniki vseh obravnavanih parcel z mejo, ki jo je
                predlagal geodet na mejni obravnavi strinjajo, se taka meja, v
                zemljiškem katastru, evidentira kot urejena meja. Geodetska
                uprava RS izda o urejenosti meje odločbo, ki jo prejmejo
                lastniki vseh obravnavanih parcel. Meja, ki jo je geodet
                predlagal, ne sme odstopati od podatkov zemljiškega katastra.
                <br />
                <br />S pravnomočnostjo odločbe je postopek evidentiranja
                urejene meje končan.
              </p>
            ),
          },
        ]}
      />
      <WhatIfSection
        items={[
          {
            title:
              'Eden od lastnikov vpletenih parcel ne pride na mejno obravnavo.',
            text: 'Če so bili vsi lastniki vpletenih parcel pravilno vabljeni, to ni ovira za izvedbo mejne obravnave. Geodet bo v zapisnik napisal, da nekoga ni bilo in ni imel možnosti, da bi se izrazil glede strinjanja o poteku predlagane meje. Takemu lastniku bo dana ta možnost še enkrat in sicer na Geodetski upravi RS, saj ga bo le ta pozvala, da izrazi strinjanje oz. ne strinjanje s predlogom poteka meje.',
          },
          {
            title:
              'Eden ali več lastnikov vpletenih parcel se z geodetovim predlogom ne strinja.',
            text: 'Lastnik vpletene parcele, ki se z geodetovim predlogom poteka meje ne strinja, mora geodetu pokazati, kje on misli, da meja poteka, geodet pa mora pokazano mejo prikazati na skici meritve. Tudi Geodetska uprava RS, takega lastnika pozove, da da izjavo o strinjanju s predlagano mejo, ki jo je predlagal geodet, ali pa pove, kje bi morala, po njegovem, meja potekati. Geodetska uprava nato odloči ali bi meja lahko potekala tako kot je to predlaga lastnik parcele ali mora obveljati geodetov predlog. Če je geodet dobro opravil svoje delo in se lastnik s predlagano mejo še vedno ne strinja, ga Geodetska uprava RS pozove, da v tridesetih dneh sproži sodni postopek. V kolikor tega ne stori, Geodetska uprava RS šteje, kakor da se s potekom predlagane mej strinja in izda odločbo o urejenosti meje.',
          },
          {
            title: 'Sodni postopek o ureditvi sporne meje.',
            text: 'Če je bil sodni postopek sprožen, Geodetska uprava RS začasno (s sklepom) zaustavi postopek in ga nadaljuje le, če se sodni postopek ustavi ali se predlog za sodno določitev meje zavrže ali zavrne. Takrat se kot urejena meja evidentira tista, ki jo je predlagal geodet. Če sodišče odloči o ureditvi meje, se evidentiranje urejene meje na Geodetski upravi RS s sklepom ustavi.',
          },
        ]}
      />
      <ServicesBottom />
    </Fragment>
  );
};

export default UrejanjeMeje;
