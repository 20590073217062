import { Tab, Tabs } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import ServiceInfo from '../../components/ServiceInfo';
import { services } from '../../Helpers';

export const ServicePresentationSectionMobile: FunctionComponent = () => {
  const [selectedService, setSelectedService] = useState<number>(0);

  // TODO: Make responsive
  return (
    <div className="container mx-auto flex flex-col gap-8">
      <div className="w-screen -ml-6 pl-6">
        <Tabs
          value={selectedService}
          onChange={(e, value) => setSelectedService(value)}
          variant="scrollable"
          scrollButtons={false}
          allowScrollButtonsMobile
        >
          {services.map((service, i) => (
            <Tab label={service.title} value={i} key={i} />
          ))}
        </Tabs>
      </div>
      <img
        src={`/home/${services[selectedService].img}`}
        className="h-[210px] w-[210px] self-center"
      />
      <ServiceInfo service={services[selectedService]} />
    </div>
  );
};

export default ServicePresentationSectionMobile;
