export const services = [
  {
    img: 'service1.png',
    ownersText:
      'Sosed posega v vaše zemljišče? Prodajate nepremičnino? Uredite mejo in ohranite dobre odnose s sosedi.',
    buyersText:
      'Pred nakupom nepremičnine preverite površino parcele in obnovite mejnike.',
    agentsText:
      'Ne prodajte mačka v žaklju in določite mejo parcele že pred prodajo.',
    title: 'Urejanje meje',
    link: '/urejanje-meje',
  },
  {
    img: 'service2.png',
    ownersText:
      'Želite prodati del dvorišča? Podariti del parcele otrokom? S parcelacijo pridobite novo parcelno številko.',
    buyersText:
      'V postopku parcelacije pred nakupom pridobite novo parcelno številko.',
    agentsText:
      'Iztržite več. Večji kos zemljišča razdelite na manjše parcele za najvišjo kupnino.',
    title: 'Parcelacija',
    link: '/parcelacija',
  },
  {
    img: 'service3.png',
    ownersText:
      'Se lotevate gradnje? Pričnite s kvalitetnim geodetskim načrtom, ki bo osnova za dober projekt.',
    buyersText:
      'Z dobrim geodetskim načrtom boste novo hišo dobro umestili v prostor, preprečili spore s sosedi in pocenili gradnjo.',
    agentsText:
      'Pri projektiranju zahtevajte geodetski načrt, ki mu 100% zaupate.',
    title: 'Geodetski načrt',
    link: '/geodetski-nacrt',
  },
  {
    img: 'service4.png',
    ownersText:
      'Nov pomožni objekt ali prizidek k vaši hiši natančno umestite v prostor ter ohranite dobre odnose z vašimi sosedi.',
    buyersText:
      'Ob prišetku gradnje svoj bodoči dom natančno umestite v prostor s postopkom zakoličbe objekta.',
    agentsText:
      'Svoj projekt natančno prenesite v naravo in zagotovite, da bo vaša stranka zadovoljna s svojim novim domom.',
    title: 'Zakoličba objekta',
    link: '/zakolicba',
  },
  {
    img: 'service5.png',
    ownersText:
      'Potrebujete hišno stevilko? Želite urediti razmerja med lastniki večtanovanjskega objekta z vzpostavitvijo etažne lastnine. Potem potrebujete vpis stavbe v kataster nepremičnin.',
    buyersText:
      'Pred nakupom nepremičnine uredite katastrski vpis stave v kataster nepremičnin ter se izognite kasnejšim zapletom pri obnovi nepremičnine.',
    agentsText:
      'Pred prodajo nepremičnine ali legalizacijo nelegalne stavbe vpišite stavbo v kataster nepremičnin ter pohitrite postopek odobritve kredita bodočemu kupcu.',
    title: 'Vpis stavbe',
    link: '/vpis-stavbe',
  },
];

export const blogLink: () => string = () => {
  return 'https://blog.center-nepremicnin.povio-projects.online';
  // if (
  //   process.env.NODE_ENV === 'production'
  // ) {
  //   return 'https://blog.center-nepremicnin.si';
  // } else {
  //   return 'https://blog.center-nepremicnin.povio-projects.online';
  // }
};
