import { FunctionComponent, useEffect, useState } from 'react';

interface Props {
  title: string;
  items: { title: string; content: React.ReactNode }[];
}
export const ServicesExplanationSection: FunctionComponent<Props> = ({
  title,
  items = [],
}) => {
  const [selectedService, setSelectedService] = useState(0);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const itemsSize = {
    height: isDesktop ? `${items.length * 90}px` : '',
    width: isDesktop ? '' : `${items.length * 210}px`,
  };

  const flexibleSize = {
    height: isDesktop ? `${selectedService * 90}px` : '',
    width: isDesktop ? '' : `${selectedService * 210}px`,
  };

  return (
    <div className="md:px-20 md:mb-20 mb-16 container mx-auto">
      <h4 className="font-medium text-neutral/900 text-2xl mb-10 px-6 md:px-0">
        {title}
      </h4>
      <div className="flex flex-col md:flex-row md:items-stretch gap-6">
        <div className="flex-1 flex flex-col-reverse md:flex-row min-w-[260px] overflow-scroll scrollbar-hide px-6 md:px-0">
          <div className="md:w-[4px] md:h-auto h-[4px] flex flex-col md:flex-row">
            <div
              className="md:w-[2px] md:h-auto h-[2px] md:mx-auto bg-neutral/300"
              style={itemsSize}
            />
            <div
              className="flex flex-row md:flex-col -mt-1 md:mt-0 md:-ml-1"
              style={itemsSize}
            >
              <div
                className="transition-all ease-in-out duration-300"
                style={flexibleSize}
              ></div>
              <div className="h-[4px] w-[210px] md:h-[90px] md:w-[4px] bg-neutral/900 z-20" />
            </div>
          </div>
          <div
            className="md:w-full h-full flex flex-row md:flex-col relative"
            style={itemsSize}
          >
            {items.map((item, i) => (
              <div
                className={`${
                  i === selectedService
                    ? 'text-neutral/900'
                    : 'text-neutral/300'
                } w-[210px] h-[84px] md:w-full md:h-[90px] px-6 z-10 transiton-all cursor-pointer flex items-center`}
                onClick={() => setSelectedService(i)}
              >
                <a className="font-lexend text-center md:text-left font-medium text-sm md:text-xl md:leading-[26px] mx-auto md:mx-0">
                  {item.title}
                </a>
              </div>
            ))}
            <div className="w-full h-full absolute top-0 flex flex-row md:flex-col">
              <div
                className="transition-all ease-in-out duration-300"
                style={flexibleSize}
              ></div>
              <div className="w-[210px] md:w-full md:h-[90px] bg-neutral/50" />
            </div>
          </div>
        </div>
        <div className="flex-1 bg-neutral/50 rounded-lg py-6 px-8 md:p-10 mx-6 md:mx-0 text-base leading-7 text-neutral/700">
          {items[selectedService].content}
        </div>
      </div>
    </div>
  );
};

export default ServicesExplanationSection;
