import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { FunctionComponent } from 'react';

interface Props {
  items: { title: string; text: string }[];
  hideSubtitle?: boolean;
}
export const WhatIfSection: FunctionComponent<Props> = ({
  items,
  hideSubtitle,
}) => {
  return (
    <div className="w-full px-6 py-16 md:p-24 text-center bg-neutral/900">
      <div className="container mx-auto">
        <h1 className="text-2xl leading-[34px] md:text-[32px] md:leading-10 mx-auto mb-6 font-medium text-white">
          Kaj pa če?
        </h1>
        {hideSubtitle ? null : (
          <h5 className="max-w-[438px] text-base leading-7 text-neutral/300 mx-auto mb-10 md:mb-24">
            Na mejni obravnavi se lahko zgodi marsikaj.
          </h5>
        )}
        {items.map((item, i) => (
          <Accordion
            className={`text-left ${
              i < items.length - 1 ? 'border-b-[1px] border-[#283662]' : ''
            }`}
            defaultExpanded={i === 0}
          >
            <AccordionSummary expandIcon={<img src="/icons/chevron.svg" />}>
              <h4 className="text-base md:py-6 text-white">{item.title}</h4>
            </AccordionSummary>
            <AccordionDetails className="text-neutral/300">
              <p className="text-base leading-7 mt-4 text-neutral/300">
                {item.text}
              </p>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default WhatIfSection;
