import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Footer: FunctionComponent = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const storitve = [
    { title: 'Urejanje meje', link: '/urejanje-meje' },
    { title: 'Parcelacija', link: '/parcelacija' },
    { title: 'Geodetski načrt', link: '/geodetski-nacrt' },
    { title: 'Zakoličba objekta', link: '/zakolicba' },
    { title: 'Vpis stavbe', link: '/vpis-stavbe' },
  ];

  const oPodjetju = [
    { title: 'O nas', link: '/about' },
    { title: 'Blog', link: '/blog' },
    { title: 'Kontakt', link: '/contact' },
  ];

  const legalSection = () => (
    <LegalContainer>
      <Link to={'/about'}>
        <LinkText>Pravilnik o zasebnosti</LinkText>
      </Link>
      <Link to={'/about'}>
        <LinkText>Splošni pogoji poslovanja</LinkText>
      </Link>
    </LegalContainer>
  );

  return (
    <div className="w-full bg-neutral/900">
      <Container className="container mx-auto">
        <ContactSection>
          <img
            src="/home/geoprojekt-logo-yellow.png"
            alt="Geoprojekt"
            className="w-32 md:w-[165px]"
          />

          <MainContactLink href="mailto: info@geoprojekt.si">
            info@geoprojekt.si
          </MainContactLink>
          <MainContactLink href="tel: (03) 710 38 10">
            (03) 710 38 10
          </MainContactLink>
          <img src="/icons/facebook.svg" className="h-8 w-8" />
          {isDesktop ? legalSection() : null}
        </ContactSection>
        <LinkColumnContainer>
          <Title>Naše storitve</Title>
          {storitve.map((item) => (
            <Link to={item.link} key={item.title}>
              <LinkText>{item.title}</LinkText>
            </Link>
          ))}
        </LinkColumnContainer>
        <LinkColumnContainer>
          <Title>O podjetju</Title>
          {oPodjetju.map((item) => (
            <Link to={item.link} key={item.title}>
              <LinkText>{item.title}</LinkText>
            </Link>
          ))}
        </LinkColumnContainer>
        <UnitColumnContainer>
          <div>
            <UnitTitle>Poslovalnica Slovenske Konjice</UnitTitle>
            <UnitSubtitle>
              Stari trg 9<br />
              3320 Slovenjske Konjice
              <br />
              (03) 620 98 84
            </UnitSubtitle>
          </div>
          <div>
            <UnitTitle>Poslovalnica Žalec</UnitTitle>
            <UnitSubtitle>
              Šlandrov trg 11
              <br />
              3310 Žalec
              <br />
              (03) 710 38 10
            </UnitSubtitle>
          </div>
        </UnitColumnContainer>
        <UnitColumnContainer>
          <UnitTitle>Poslovalnica Ljubljana</UnitTitle>
          <UnitSubtitle>
            Ulica Kunaver 9
            <br />
            1000 Ljubljana
            <br />
            (03) 320 77 30
          </UnitSubtitle>
        </UnitColumnContainer>
        <CopyrightSection>
          <CopyrightText>
            © 1991-2015 Consulting Services |<br />
            Geoprojekt, all rights reserved
          </CopyrightText>
        </CopyrightSection>
        {isDesktop ? null : legalSection()}
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 92px;

  padding: 96px 80px;
  gap: 8px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    padding: 40px 24px;
    row-gap: 40px;
    column-gap: 20px;
  }
`;

const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  grid-column: 1 / span 2;
  grid-row: 1 / span 1;

  @media (max-width: 768px) {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
  }
`;

const CopyrightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  grid-column: 1 / span 2;
  grid-row: 2 / span 1;

  @media (max-width: 768px) {
    grid-column: 1 / span 2;
    grid-row: 6 / span 1;
  }
`;

const LegalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 768px) {
    grid-column: 1 / span 2;
    grid-row: 5 / span 1;
  }
`;

const MainContactLink = styled.a`
  font-family: 'Lexend';
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  text-decoration: none;
  color: white;
`;

const Title = styled.h5`
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;

  color: white;
`;

const LinkText = styled.a`
  font-family: 'Lato';
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;

  text-decoration: none;
  color: #bbc0d1;
`;

const UnitTitle = styled.h5`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;

  color: #dcdee5;
`;

const UnitSubtitle = styled.h5`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;

  color: #90a4ae;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

const LinkColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UnitColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;

  @media (max-width: 768px) {
    gap: 36px;
  }
`;

const CopyrightText = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;

  color: #9194ab;
  margin-bottom: 0px;
`;
