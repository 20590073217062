import { Fragment, FunctionComponent } from 'react';
import ConditionsSection from '../../components/ConditionsSection';
import ImageBulletSection from '../../components/ImageBulletSection';
import NavBar from '../../components/NavBar';
import ServiceHero from '../../components/ServiceHero';
import ServicesBottom from '../../components/ServicesBottom';
import ServicesExplanationSection from '../../components/ServicesExplanationSection';
import StepsSection from '../../components/StepsSection';
import WhatIfSection from '../../components/WhatIfSection';

export const VpisKataster: FunctionComponent = () => {
  return (
    <Fragment>
      <NavBar />
      <ServiceHero
        title={'Vpis stavbe v kataster stavb'}
        subtitle={
          'Vpis stavbe v kataster stavb je geodetski postopek, s katerim vpišemo podatke o stavbi v kataster stavb.'
        }
      />
      <ImageBulletSection
        imgSrc={'/home/service5.png'}
        title={'Kdaj morate vpisati stavbo v kataster stavb?'}
        bullets={[
          'Ste zgradili ali dogradili ali podrli objekt?',
          'Potrebujete hišno številko?',
          'Ste dobili poziv Geodetske uprave RS, da ste v roku treh mesecev dolžni vrisati objekt v zemljiški kataster in kataster stavb?',
          'Želite prodati zemljišče s stavbo, pa ta ni vpisana v kataster stavb?',
          'Želite prodati stanovanje v več stanovanjski stavbi, pa le-to ni vpisano v zemljiški knjigi?',
        ]}
      />
      <ConditionsSection
        title={'Pogoji za izvedbo vpisa stavbe v kataster stavb'}
        subtitle={
          'Za izvedbo vpisa stavbe v kataster stavb moramo preveriti 3 pogoje.'
        }
        conditions={[
          {
            img: '/icons/icon1.png',
            text: 'Objekt mora biti zaprt. Torej mora imeti okna in vrata.',
          },
          {
            img: '/icons/icon2.png',
            text: 'Objekt mora imeti izvedene notranje omete sten',
          },
          {
            img: '/icons/icon3.png',
            text: 'Objekt mora biti pokrit. To je postavljena streha in napravljen zaključni sloj znotraj strehe.',
          },
        ]}
      />
      <StepsSection
        title={'Kako poteka postopek vpisa stavbe v kataster stavb?'}
        subtitle={
          'Vpis stavbe v kataster stavb poteka v dveh fazah. Najprej geodet naredi vpis stavbe v kataster stavb, nato pa Geodetska uprava RS opravi še evidentiranje podatkov o stavbi v kataster stavb.'
        }
        steps={[
          'Geodet naredi vpis stavbe v kataster stavb.',
          'Geodetska uprava RS opravi evidentiranje podatkov o stavbi v kataster stavb.',
        ]}
      />
      <ServicesExplanationSection
        title="Geodetove naloge"
        items={[
          {
            title: 'Vabila na meritev',
            content: (
              <Fragment>
                <p>
                  Geodet lahko stranke povabi ustno ali pisno. Pisne oblike
                  vabljenja se poslužuje takrat, ko ima opravka s stavbo z več
                  deli (stanovanji ali poslovnimi prostori). S tem si zagotovi,
                  da bodo vse stranke na objektu in bo le tako lahko pomeril vse
                  prostore objekta. Pri individualnih objektih se geodet
                  običajno poslužuje ustnega vabljenja.
                </p>
              </Fragment>
            ),
          },
          {
            title: 'Terenske meritve',
            content: (
              <Fragment>
                <p className="mb-10">
                  Geodet najprej izpolni vpisne obrazce. To so osnovni podatki o
                  stavbi, ki mu jih pove lastnik.
                  <br />
                  <br />
                  Gre za podatke o stavbi:
                  <ul className="list-disc list-inside mt-2">
                    <li>
                      Ali je to samostoječa stavba ali vmesna vrsta stavba ali
                      končna vrstna stavba ali stavba dvojček?
                    </li>
                    <li>Kdaj je bila stavba zgrajena?</li>
                    <li>Kdaj je bila zadnja obnova strehe in fasade?</li>
                    <li>Kakšen je material nosilne konstrukcije?</li>
                    <li>Kakšen je način ogrevanja?</li>
                    <li>
                      Ali je objekt priključen na vodovod, elektriko,
                      kanalizacijo in kabelsko TV?
                    </li>
                    <li>Ali ima objekt dvigalo?</li>
                    <li>Kakšen je način temeljenja?</li>
                    <li>
                      Ali je objekt priključen na plin, tehnološki plin,
                      industrijski tok in komprimiran zrak?
                    </li>
                    <li>Ali ima kakšno posebno čistilno napravo?</li>
                  </ul>
                  <br />
                  ter za podatke o delu stavbe (vse spodaj navedeno se nanaša na
                  stanovanjske stavbe):
                  <ul className="list-disc list-inside mt-2">
                    <li>
                      Kje je vhod v stanovanje (klet, pritličje, nadstropje,
                      mansarda ali drugo)?
                    </li>
                    <li>
                      Kdaj so bila nazadnje obnovljena okna in kdaj inštalacije?
                    </li>
                    <li>
                      Ali je del stavbe v najemu in če je, v kakšnem najemu?
                    </li>
                    <li>Ali se del stavbe nahaja v dveh ali večih etažah?</li>
                    <li>
                      Ali ima del stavbe klimo, kuhinjo, kopalnico in stranišče?
                    </li>
                    <li>Koliko sob ima del stavbe?</li>
                    <li>
                      Ali je v stanovanju prijavljena kakšna dejavnost, ter
                      koliko sob se uporavlja za to dejavnost in njihova
                      površina?
                    </li>
                    <li>
                      Parkirni prostori in njih število namenjenih temu delu
                      stavbe!
                    </li>
                    <li>Ali se stanovanje uporablja v počitniške namene?</li>
                    <li>Ter posebni prostori, ki pripadaju stanovanju!</li>
                  </ul>
                </p>
                <p>
                  Te popisne obrazce lastnik oziroma oseba, ki je dala podatke
                  podpiše in so sestavni del elaborata za vpis stavbe v kataster
                  stavb.
                  <br />
                  <br />
                  Ko so vpisni obrazci izpolnjeni in podpisani, geodet prične z
                  izmero prostorov. Vsak lastnik dela stavbe mora geodetu
                  omogočiti dostop do vseh prostorov njegovega dela stavbe. Po
                  izmerah prostorov, sledi še zunanja izmera objekta.
                </p>
              </Fragment>
            ),
          },
          {
            title: 'Izdelava elaborata vpisa stavbe v kataster stavb',
            content: (
              <Fragment>
                <p>
                  Z vsemi izmerami geodet v pisarni izdela osnutek elaborata
                  vpisa stavbe v kataster stavb.
                  <br />
                  <br />
                  Najprej z notranjimi izmerami prostorov izračuna površine
                  prostorov. Z računalniškim programom nato izdela osnutek
                  elaborata za vpis stavbe v kataster stavb.
                </p>
              </Fragment>
            ),
          },
          {
            title: 'Ponovno vabljenje strank',
            content: (
              <Fragment>
                <p>
                  Tokrat lahko geodet povabi stranke v pisarno geodetskega
                  podjetja ali pa ponovno na objekt. Lahko jih povabi ustno ali
                  pisno.
                </p>
              </Fragment>
            ),
          },
          {
            title:
              'Predstavitev osnutka elaborata za vpis stavbe v kataster stavb in podpis zapisnika.',
            content: (
              <Fragment>
                <p>
                  Geodet predstavi osnutek elaborata strankam v postopku. Napiše
                  zapisnik o razgrnitvi elaborata. Če katera od strank opomni na
                  napake v elaboratu, geodet napake odpravi in ponovi vabljenje.
                  <br />
                  <br />
                  Ko vse stranke v postopku podpišejo zapisnik, iz osnutka
                  elaborata za vpis stavbe v kataster stavb postane elaborat za
                  vpis stavbe v kataster stavb in je kot tak pripravljen za
                  predajo na Geodetsko upravo RS, s čimer se konča geodetovo
                  delo in prične se druga faza - evidentiranje vpisa stavbe v
                  kataster stavb.
                </p>
              </Fragment>
            ),
          },
        ]}
      />
      <div className="mb-16 px-6 container mx-auto md:mb-24 md:px-20">
        <h4 className="font-medium text-xl md:text-2xl md:mb-6 text-neutral/900">
          Evidentiranje podatkov o stavbi v kataster stavb
        </h4>
        <p className="text-base leading-7 text-neutral/700">
          Geodetska uprava RS o zahtevah odloči s sklepom in stavbo vpiše v
          kataster stavb.
        </p>
      </div>
      <WhatIfSection
        hideSubtitle
        items={[
          {
            title: 'Gre za stavbo enega lastnika in ima stavba en sam del?',
            text: 'Če gre za stavbo enega lastnika in ima stavba en del (npr. eno stanovanjska hiša), mora vlagatelj predložiti še elaborat za določitev zemljišča pod stavbo.',
          },
          {
            title:
              'Želite v stavbi z več deli to stavbo vpisati v kataster stavb (če želite svoj del prodati, je to nujno potrebno), pa eden od solastnikov stavbe tega ne želi storiti?',
            text: 'Najprej ugotovite, zakaj solastnik tega ne želi storiti: če nima denarja, da bi pokril stroške geodeta, je mogoče smiselno, da mu jih pokrijete vi; če pa so zadržki kje drugje, pa vam preostane samo ena možnost. Reševanje etažne lastnine po ZVEtL (Zakon o vzpostavitvi etažne lastnine na predlog pridobitelja posameznega dela stavbe in o določanju pripadajočega zemljišča k stavbi).',
          },
          {
            title:
              'Stavba nima hišne številke, pa bi jo po predpisih o imenovanju naselji, ulic in stavb morala imeti?',
            text: 'V tem primeru morate poleg zahteve za vpis stavbe v kataster stavb in elaborata za vpis stavbe v kataster stavb podati še zahtevo za določitev hišne številke. Tudi to lahko v vašem imenu stori geodet.',
          },
        ]}
      />
      <ServicesBottom />
    </Fragment>
  );
};

export default VpisKataster;
