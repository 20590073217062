import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { services } from '../Helpers';

interface Props {
  selectedService?: number;
  setSelectedService: Dispatch<SetStateAction<number>>;
}
export const SelectSidebar: FunctionComponent<Props> = ({
  selectedService = 0,
  setSelectedService,
}) => {
  return (
    <div className="flex-1 flex flex-row min-w-[260px] h-[450px]">
      <div className="h-full w-[4px] flex flex-row">
        <div className="h-full w-[2px] mx-auto bg-neutral/300" />
        <div className="flex flex-col -ml-1">
          <div
            className="transition-all ease-in-out duration-300"
            style={{ height: `${selectedService * 20}%` }}
          ></div>
          <div className="h-1/5 w-[4px] bg-neutral/900" />
        </div>
      </div>
      <div className="w-full h-full flex flex-col relative">
        {services.map((service, i) => (
          <button
            onClick={() => setSelectedService(i)}
            className={`${
              i === selectedService ? 'text-neutral/900' : 'text-neutral/300'
            } w-full h-1/5 px-6 py-8 z-10 transiton-all font-lexend text-left font-medium text-xl leading-[26px]`}
          >
            <span>{service.title}</span>
          </button>
        ))}
        <div className="w-full h-full absolute top-0 flex flex-col">
          <div
            className="transition-all ease-in-out duration-300"
            style={{ height: `${selectedService * 20}%` }}
          ></div>
          <div className="h-1/5 w-full bg-neutral/50" />
        </div>
      </div>
    </div>
  );
};

export default SelectSidebar;
