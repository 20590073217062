import { Fragment, FunctionComponent } from 'react';
import ImageBulletSection from '../../components/ImageBulletSection';
import NavBar from '../../components/NavBar';
import ServiceHero from '../../components/ServiceHero';
import ServicesBottom from '../../components/ServicesBottom';
import ServicesExplanationSection from '../../components/ServicesExplanationSection';
import StepsSection from '../../components/StepsSection';
import CustomIconTextSection from './CustomIconTextSection';

export const GeodetskiNacrt: FunctionComponent = () => {
  return (
    <Fragment>
      <NavBar />
      <ServiceHero
        title={'Geodetski načrt'}
        subtitle={
          'Geodetski načrt oz. geodetski posnetek je načrt stanja zemljišča, objektov in infrastrukture na določen dan.'
        }
      />
      <ImageBulletSection
        imgSrc={'/home/service3.png'}
        title={'Kdaj potrebujete geodetski načrt?'}
        bullets={[
          'Ste se odločili zgraditi nov objekt?',
          'Ste zgradili poslovni objekt in potrebujete uporabno dovoljenje?',
          'Želite pripraviti Občinski Podrobni Prostorski Načrt?',
          'Želite svoj objekt priključiti na elektro vod?',
        ]}
      />
      <ImageBulletSection
        imgSrc={'/service-desc/documents.svg'}
        title={'Zakaj potrebujete kvaliteten geodetski načrt?'}
        bullets={[
          'Geodetski načrt oz. geodetski posnetek je prva geodetska storitev, ki jo boste potrebovali pri pripravi projektne dokumentacije za pridobitev gradbenega dovoljenja za novo hišo.',
          'Od kakovosti izdelave geodetskega načrta je odvisno to, kako zanesljivo bo lahko vaš arhitekt umestil vašo novo hišo na parcelo. Predvsem od pravilne interpretacije parcelnih mej pa bo odvisno ustrezno pridobivanje soglasij pristojnih služb, mejašev in ostalih vpletenih v postopek pridobivanja projektne dokumentacije.',
        ]}
        imgLeft
      />
      <CustomIconTextSection />
      <StepsSection
        title={'Kako nastaja geodetski načrt?'}
        steps={[
          'Investitor gradnje, lastnik zemljišča… pri geodetu naroči geodetski načrt.',
          'Geodet na Geodetski upravi RS naroči izrez parcel, ki bodo katastrska podlaga geodetskega načrta.',
          'Geodet se z naročnikom dogovori, kdaj bo prišel na teren izvesti meritve.',
          'Geodet izvede meritve',
          'Geodet izriše načrt in izdela elaborat geodetskega načrta.',
        ]}
      />
      <ServicesExplanationSection
        title="Postopki izdelave geodetskega načrta"
        items={[
          {
            title: 'Geodetska izmera',
            content: (
              <p>
                Geodet na terenu najprej stabilizira geodetsko mrežo. To napravi
                bodisi z GPS instrumentom ali pa poišče kakšne znane geodetske
                točke v oklici. Nato določi obseg geodetskega načrta in nariše
                skico meritve. Obseg je odvisen od lege bodočega ali
                novozgrajenega objekta in sicer znaša 25 m od objekta v
                obravnavi. Na skico meritve nariše vse kar bo zajel. Zajeti mora
                trenutno stanje zemljišča in sicer:
                <ul className="list-disc list-inside mt-2">
                  <li>grajene objekte v bližino (stavbe, ceste, mostove…),</li>
                  <li>
                    naravo (vrsta vegetacije, posamezna drevesa, zelenice…),
                  </li>
                  <li>
                    vodotoke (jezera, reke, potoke, regulacijske jarke, jarke z
                    nestalnimi vodami…),
                  </li>
                  <li>relief</li>
                  <li>infrastrukturo (kanalizacija, vodovod, elektrika…).</li>
                  <li>označitve mej parcel (mejna znamenja, uživalne meje)</li>
                </ul>
              </p>
            ),
          },
          {
            title: 'Izris načrta',
            content: (
              <Fragment>
                <p className="mb-10">
                  V pisarni geodet zajete podatke preračuna in izriše geodetski
                  načrt s po močjo skice, ki jo je vodil na terenu.
                </p>
                <p>
                  Na načrt vriše vse zajete elemente. Dodati mora še
                  zemljiško-katastrsko podlago, geodetske križe, napise krajev,
                  ulic, cest… Relief pri dokaj ravnem terenu prikazujejo kar
                  višinske kote izpisane pri vseh izmerjenih točkah. Če pa gre
                  za bolj razgiban in strm teren, ga prikazuje s plastnicami in
                  kartografskim znakom za nasipe. Geodetski načrt opremi še z:
                  <ul className="list-disc list-inside mt-2">
                    <li>legendo topografskih znakov in tipov linij,</li>
                    <li>napisi o izvajalcu geodetskega načrta,</li>
                    <li>naročnikom geodetskega načrta,</li>
                    <li>številko geodetskega načrta,</li>
                    <li>žigom in podpisom odgovornega geodeta.</li>
                  </ul>
                </p>
              </Fragment>
            ),
          },
          {
            title: 'Izdelava elaborata geodetskega načrta',
            content: (
              <p>
                Elaborat geodetskega načrta je sestavljen iz tekstualnega in
                grafičnega dela. Grafični del je geodetski načrt, ki je opisan
                zgoraj, tekstualni del pa je sestavljen iz:
                <ul className="list-disc list-inside mt-2">
                  <li>naslovne strani,</li>
                  <li>kazala in</li>
                  <li>certifikata geodetskega načrta.</li>
                </ul>
              </p>
            ),
          },
          {
            title: 'Certifikat geodetskega načrta',
            content: (
              <p>
                Certifikat geodetskega načrta navaja:
                <ul className="list-disc list-inside mt-2 mb-4">
                  <li>kdo je izdelovalec geodetskega načrta,</li>
                  <li>
                    kdo je naročnik geodetskega načrta in/ali investitor
                    gradnje,
                  </li>
                  <li>za kakšen namen je bil geodetski načrt izdelan,</li>
                  <li>za katere parcele je bil geodetski načrt izdelan,</li>
                  <li>kdaj je bila vzpostavljena geodetska mreža,</li>
                  <li>kdaj je bila izvedena geodetska izmera,</li>
                  <li>od kod in kdaj so prevzeti podatki o infrastrukturi,</li>
                  <li>
                    katere poligonske točke so bile uporabljene pri izmeri in
                    navedba njihovih koordinat,
                  </li>
                  <li>
                    navedba o koordinatnem sistemu, v katerem je izveden
                    geodetski načrt,
                  </li>
                  <li>
                    navedba datuma uporabljene zemljiško-katastrske podlage in
                    natančnost prikazanih mej parcel.
                  </li>
                </ul>
                Celoten elaborat (tudi certifikat in geodetski načrt) mora biti
                ožigosan in podpisan s strani odgovornega geodeta, ki je
                geodetski načrt pregledal.
              </p>
            ),
          },
        ]}
      />
      <ServicesExplanationSection
        title="Tipi geodetskih načrtov"
        items={[
          {
            title:
              'Geodetski načrt za potrebe pridobitve gradbenega dovoljenja',
            content: (
              <p>
                To je najbolj standarden tip geodetskega načrta. Prikazuje vse
                zgoraj našteto. Običajno je izdelan v merilu 1: 500, v kolikor
                pa gre za zelo gosto poseljeno območje pa za merilo 1:250.
              </p>
            ),
          },
          {
            title:
              'Geodetski načrt za potrebe pridobitve uporabnega dovoljenja',
            content: (
              <p>
                Ta geodetski načrt potrebujejo vsi objekti poslovne,
                industrijske, večstanovanjske… gradnje, torej bolj kompleksne
                gradnje. Geodetski načrt PID, kot ga imenujemo (Po Izvedenih
                Delih), se od prejšnjega razlikuje po tem, da ima zelo podrobno
                definirano novozgrajeno infrastrukturo. Običajno je izdelan v
                merilu 1:250, saj je stopnja detajla takšnega načrta precej
                višja.
              </p>
            ),
          },
          {
            title: 'Geodetski načrt za potrebe priključitve na elektro vod',
            content: (
              <p>
                Elektro podjetja pogojujejo priklopa na električno omrežje z
                izdelavo geodetskega načrta elektro vodov. Geodet na terenu
                zajame potek električnega kabla od priklopne elektro omarice, do
                objekta oziroma nove elektro omarice.
                <br />
                <br />
                Elaborat ima dodatno vsebino in sicer seznam koordinat lomnih
                točka elektro voda in elektro omaric.
              </p>
            ),
          },
        ]}
      />
      <ServicesBottom />
    </Fragment>
  );
};

export default GeodetskiNacrt;
