import { Fragment, FunctionComponent } from 'react';
import ContactCallout from '../pages/Home/ContactCallout';
import { LogosSection } from '../pages/Home/LogosSection';
import { Footer } from './Footer';

export const ServicesBottom: FunctionComponent = () => {
  return (
    <Fragment>
      <ContactCallout />
      <LogosSection />
      <Footer />
    </Fragment>
  );
};

export default ServicesBottom;
