import { FunctionComponent } from 'react';

interface Props {
  title: string;
  subtitle: string;
  conditions: { img: string; text: string }[];
}
export const ConditionsSection: FunctionComponent<Props> = ({
  title,
  subtitle,
  conditions,
}) => {
  return (
    <div className="w-full bg-neutral/50 px-6 py-16 md:py-24 md:px-20 text-center mb-16 md:mb-0">
      <div className="container mx-auto">
        <h4 className="font-medium text-2xl md:text-[32px] leading-[34px] md:leading-[40px] mb-6 text-neutral/900">
          {title}
        </h4>
        <h6 className="text-base leading-7 text-neutral/700 mb-10 md:mb-12">
          {subtitle}
        </h6>
        <div className="flex flex-col md:flex-row flex-wrap items-stretch justify-center mx-auto text-left gap-10">
          {conditions.map((condition, i) => (
            <div
              className={`md:p-12 ${
                conditions.length % 3 === 0 ? 'max-w-[357px]' : 'max-w-[548px]'
              }`}
              key={i}
            >
              <img src={condition.img} className="w-12 h-12 mb-2 md:mb-4" />
              <p className="text-base leading-7 text-neutral/700">
                {condition.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ConditionsSection;
