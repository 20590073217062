import { FunctionComponent } from 'react';
import Section from '../../components/Section';
import ServicePresentationSectionMobile from './ServicePresentationSectionMobile';

export const ServicesSectionMobile: FunctionComponent = () => {
  return (
    <Section
      title="NAŠE STORITVE"
      tagline="Geodetske storitve za 
      vaše nepremičnine"
      description="Opišite nam vaš izziv, naša ekipa geodetov pa vam bo predlagala ustrezen nabor geodetskih storitev."
    >
      <ServicePresentationSectionMobile />
    </Section>
  );
};

export default ServicesSectionMobile;
