import { FunctionComponent } from 'react';
import Section from '../../components/Section';

export const TestimonialsSection: FunctionComponent = () => {
  return (
    <Section
      title="NAŠI PARTNERJI O NAS"
      tagline="Vaše mnenje nam
      pomeni največ"
    >
      <div className="flex flex-col md:flex-row items-stretch gap-6">
        <div className="flex flex-col items-center justify-center gap-6 rounded-lg bg-neutral/50 md:w-1/3 px-7 py-12">
          <div className="bg-neutral/900 rounded-full h-16 w-16"></div>
          <div>
            <p className="text-base leading-7 text-neutral/700 text-left mb-4">
              “Hitrost je zame najpomembnejša. Zato prisegam na ekipo Centra
              nepremičnin.”
            </p>
            <p className="text-sm leading-6 text-neutral/700 text-left">
              - Matevž Petek
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center gap-6 rounded-lg bg-neutral/50 md:w-1/3 px-7 py-12">
          <div className="bg-neutral/900 rounded-full h-16 w-16"></div>
          <div>
            <p className="text-base leading-7 text-neutral/700 text-left mb-4">
              “Hitrost je zame najpomembnejša. Zato prisegam na ekipo Centra
              nepremičnin.”
            </p>
            <p className="text-sm leading-6 text-neutral/700 text-left">
              - Matevž Petek
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center gap-6 rounded-lg bg-neutral/50 md:w-1/3 px-7 py-12">
          <div className="bg-neutral/900 rounded-full h-16 w-16"></div>
          <div>
            <p className="text-base leading-7 text-neutral/700 text-left mb-4">
              “Hitrost je zame najpomembnejša. Zato prisegam na ekipo Centra
              nepremičnin.”
            </p>
            <p className="text-sm leading-6 text-neutral/700 text-left">
              - Matevž Petek
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default TestimonialsSection;
