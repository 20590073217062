import { Fragment, FunctionComponent } from 'react';
import { Footer } from '../../components/Footer';
import NavBar from '../../components/NavBar';
import ServiceHero from '../../components/ServiceHero';
import { PersonalDataSection } from './PersonalDataSection';

export const Contact: FunctionComponent = () => {
  return (
    <Fragment>
      <NavBar />
      <ServiceHero
        title={'Kontaktirajte nas'}
        subtitle={
          'Zastavite nam vprašanje, naša ekipa pa vas bo kontaktirala v najkrajšem možnem času.'
        }
      />
      <div className="max-w-[548px] mx-auto px-6">
        <PersonalDataSection />
      </div>
      <Footer />
    </Fragment>
  );
};

export default Contact;
