import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import Hero from './Hero';
import ClientsSection from './ClientsSection';
import { ServicesSection } from './ServicesSection';
import ContactCallout from './ContactCallout';
import ServicesSectionMobile from './ServicesSectionMobile';
import NavBar from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import TestimonialsSection from './TestimonialsSection';
import { BlogSection } from './BlogSection';
import { LogosSection } from './LogosSection';

export const Home: FunctionComponent = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <Fragment>
      <NavBar />
      <Hero />
      <ClientsSection />
      {isDesktop ? (
        <div>
          <ServicesSection />
          <ContactCallout />
        </div>
      ) : (
        <Fragment>
          <ServicesSectionMobile />
          <ContactCallout />
        </Fragment>
      )}
      <LogosSection />
      <BlogSection />
      <TestimonialsSection />
      <Footer />
    </Fragment>
  );
};

export default Home;
