import { Fragment, FunctionComponent } from 'react';
import styled, { keyframes } from 'styled-components';
import Section from '../../components/Section';

const logosTop = [
  '/client-logos/top/bimex.png',
  '/client-logos/top/ges.png',
  '/client-logos/top/gradnjenikolic.png',
  '/client-logos/top/ogking.png',
  '/client-logos/top/remont.png',
  '/client-logos/top/soseskafrenga.png',
  '/client-logos/top/voc.png',
];

const logosBottom = [
  '/client-logos/bottom/abing.png',
  '/client-logos/bottom/gradiateam.png',
  '/client-logos/bottom/klimacelje.png',
  '/client-logos/bottom/logo.png',
  '/client-logos/bottom/maksim.png',
  '/client-logos/bottom/malteski.png',
  '/client-logos/bottom/sgpsavinja.png',
];

export const LogosSection: FunctionComponent = () => {
  return (
    <Fragment>
      <Section
        title="NAŠI PARTNERJI"
        tagline="Zaupajo nam največja imena 
        v nepremičninskem poslu."
        description="V zadnjih 31 letih so nas izbrali kot partnerja pri izvedbi geodetski storitev za več kot 10.625 nepremičninskih projektov."
        fullWidth
      >
        <Container>
          <LogoTrackContainerTop>
            {logosTop.map((img, i) => (
              <LogoBlock key={i}>
                <img src={img} />
              </LogoBlock>
            ))}
            {logosTop.map((img, i) => (
              <LogoBlock key={i}>
                <img src={img} />
              </LogoBlock>
            ))}
          </LogoTrackContainerTop>
          <LogoTrackContainerBottom>
            {logosBottom.map((img, i) => (
              <LogoBlock key={i}>
                <img src={img} />
              </LogoBlock>
            ))}
            {logosBottom.map((img, i) => (
              <LogoBlock key={i}>
                <img src={img} />
              </LogoBlock>
            ))}
          </LogoTrackContainerBottom>
        </Container>
      </Section>
    </Fragment>
  );
};

// position: absolute is used here to achieve full width
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  padding: 32px;
  margin: -32px;
  margin-top: calc(-32px + 64px);

  width: 100vw;
  overflow: hidden;
`;

const logoAnimationLTR = keyframes`
  0% { transform: translateX(70vw); }
  100% { transform: translateX(calc(-50vw - 4px)); }
`;

const logoAnimationRTL = keyframes`
  0% { transform: translateX(-70vw); }
  100% { transform: translateX(calc(50vw + 4px)); }
`;

const logoAnimationMobileLTR = keyframes`
  0% { transform: translateX(140vw); }
  100% { transform: translateX(calc(-100vw - 4px)); }
`;

const logoAnimationMobileRTL = keyframes`
  0% { transform: translateX(-140vw); }
  100% { transform: translateX(calc(100vw + 4px)); }
`;

const LogoTrackContainerTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  width: 240vw;

  transform: translateX(calc(-50vw - 4px));
  animation-name: ${logoAnimationLTR};
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @media (max-width: 650px) {
    width: 480vw;
    animation-name: ${logoAnimationMobileLTR};
  }
`;

const LogoTrackContainerBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  width: 240vw;

  transform: translateX(calc(50vw + 4px));
  animation-name: ${logoAnimationRTL};
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @media (max-width: 650px) {
    width: 480vw;
    animation-name: ${logoAnimationMobileRTL};
  }
`;

const LogoBlock = styled.div`
  width: 100%;
  aspect-ratio: 1;
  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
  box-shadow: 4px 4px 24px 8px rgba(5, 37, 73, 0.08);
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 8px;
  }
`;
