import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Section from '../../components/Section';
import { blogLink } from '../../Helpers';

export const BlogSection: FunctionComponent = () => {
  // eslint-disable-next-line
  const [posts, setPosts] = useState<any[]>([]);

  useEffect(() => {
    axios
      .get(
        `${blogLink()}/ghost/api/content/posts/?key=0ada4895cdd0d9adcd00473ef2&limit=2&filter=tag:geoprojekt`
      )
      .then((res) => setPosts(res.data.posts));
  }, []);

  return (
    <Section tagline="Sveže novice ob jutranji kavi." title="BLOG">
      <Container>
        {posts.map((post) => (
          <a href={post.url} style={{ textDecoration: 'none' }}>
            <CardContainer>
              <FeaturesImage src={post.feature_image} />
              <TextContainer>
                <Title>{post.title}</Title>
                <PostDate>
                  {new Date(post.published_at).toLocaleDateString('sl-SI', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  })}
                </PostDate>
              </TextContainer>
            </CardContainer>
          </a>
        ))}
      </Container>
      <div style={{ marginTop: '64px' }}>
        <button
          onClick={() => (window.location.href = blogLink())}
          className="max-w-[280px] w-full h-12 bg-primary/500-p rounded font-lexend font-bold text-base text-neutral/900 mb-16 md:mb-24"
        >
          Prikaži vse novice
        </button>
      </div>
    </Section>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;

  margin-top: 64px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CardContainer = styled.div`
  background: #ffffff;

  width: 450px;

  box-shadow: 4px 4px 24px 8px rgba(5, 37, 73, 0.08);
  border-radius: 8px;

  overflow: hidden;

  @media (max-width: 768px) {
    width: 325px;
  }
`;

const FeaturesImage = styled.img`
  width: 100%;
  height: 224px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 162px;
  }
`;

const TextContainer = styled.div`
  padding: 32px;
`;

const Title = styled.h4`
  font-size: 20px;
  line-height: 26px;
  color: #455a64;
  text-align: left;
`;

const PostDate = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: #607d8b;
  text-align: left;
`;
