import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  service: {
    img: string;
    ownersText: string;
    buyersText: string;
    agentsText: string;
    link: string;
  };
}
export const ServiceInfo: FunctionComponent<Props> = ({ service }) => {
  return (
    <div className="md:flex-1 md:h-full flex flex-col gap-6 md:gap-12 md:items-stretch text-left">
      <div>
        <h4 className="font-medium text-base text-neutral/900 mb-2 md:mb-3">
          Lastniki nepremičnin
        </h4>
        <h6 className="font-medium text-base text-neutral/900">
          {service.ownersText}
        </h6>
      </div>
      <div>
        <h4 className="font-medium text-base text-neutral/900 mb-3">
          Kupci nepremičnin
        </h4>
        <h6 className="font-medium text-base text-neutral/900">
          {service.buyersText}
        </h6>
      </div>
      <div>
        <h4 className="font-medium text-base text-neutral/900 mb-3">
          Nepremičninski agenti
        </h4>
        <h6 className="font-medium text-base text-neutral/900 mb-2 md:mb-0">
          {service.agentsText}
        </h6>
      </div>
      <button
        type="button"
        className="mt-auto font-lexend text-center text-white bg-primary/500-p w-full h-12 flex flex-col justify-center rounded"
      >
        <Link to={service.link}>Več</Link>
      </button>
    </div>
  );
};

export default ServiceInfo;
