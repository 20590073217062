import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import UrejanjeMeje from './pages/UrejanjeMeje/UrejanjeMeje';
import Parcelacija from './pages/Parcelacija/Parcelacija';
import GeodetskiNacrt from './pages/GeodetskiNacrt/GeodetskiNacrt';
import Zakolicba from './pages/Zakolicba/Zakolicba';
import VpisKataster from './pages/VpisKataster/VpisKataster';
import ScrollToTop from './components/ScrollToTop';
import Contact from './pages/Contact/Contact';
import LastnikiNepremicnin from './pages/LastnikiNepremicnin/LastnikiNepremicnin';
import KupciInGraditelji from './pages/KupciInGraditelji/KupciInGraditelji';
import AgentiInInvestitoriji from './pages/AgentiInInvestitoriji/AgentiInInvestitoriji';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/urejanje-meje" element={<UrejanjeMeje />} />
        <Route path="/parcelacija" element={<Parcelacija />} />
        <Route path="/geodetski-nacrt" element={<GeodetskiNacrt />} />
        <Route path="/zakolicba" element={<Zakolicba />} />
        <Route path="/vpis-stavbe" element={<VpisKataster />} />
        <Route path="/lastniki-nepremicnin" element={<LastnikiNepremicnin />} />
        <Route path="/kupci-in-graditelji" element={<KupciInGraditelji />} />
        <Route
          path="/agenti-in-investitorji"
          element={<AgentiInInvestitoriji />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
