import React, { FunctionComponent } from 'react';

interface Props {
  imgSrc: string;
  title: string;
  bullets: string[];
  bottomText?: string;
  imgLeft?: boolean;
}
export const Section: FunctionComponent<Props> = ({
  imgSrc,
  title,
  bullets,
  bottomText,
  imgLeft,
}) => {
  return (
    <div
      className={`w-full max-w-[1280px] mx-auto px-6 py-16 md:px-20 md:py-24 flex flex-col md:items-center gap-6 md:gap-10 ${
        imgLeft ? 'md:flex-row' : 'md:flex-row-reverse'
      }`}
    >
      <div className="flex-1">
        <img
          src={imgSrc}
          className="mx-auto max-h-[166px] md:max-h-[360px] w-auto"
        />
      </div>
      <div className="flex-1">
        <h4 className="text-2xl md:text-[32px] md:leading-10 text-center md:text-left leading-[34px] font-medium mb-6 md:mb-10 text-neutral/900">
          {title}
        </h4>
        <ul className="list-disc list-outside ml-6">
          {bullets.map((bullet, i) => (
            <li
              className="text-base leading-[28px] text-neutral/700 p-1.5"
              key={i}
            >
              {bullet}
            </li>
          ))}
        </ul>
        {bottomText ? (
          <p className="text-base leading-[28px] text-neutral/700 mt-10">
            {bottomText}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default Section;
