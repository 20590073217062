import { Fragment, FunctionComponent } from 'react';
import ImageBulletSection from '../../components/ImageBulletSection';
import NavBar from '../../components/NavBar';
import ServiceHero from '../../components/ServiceHero';
import ServicesBottom from '../../components/ServicesBottom';
import ServicesExplanationSection from '../../components/ServicesExplanationSection';
import StepsSection from '../../components/StepsSection';

export const Zakolicba: FunctionComponent = () => {
  return (
    <Fragment>
      <NavBar />
      <ServiceHero
        title={'Zakoličba gradbenega objekta'}
        subtitle={
          'Zakoličba je prenos objekta iz načrta v naravo in je uradni začetek gradnje.'
        }
      />
      <ImageBulletSection
        imgSrc={'/home/service4.png'}
        title={'Kdaj potrebujete zakoličbo objekta?'}
        bullets={[
          'Ste pridobili gradbeno dovoljenje?',
          'Ne veste kje poteka komunalni vod, na katerega bi se želeli priklopiti?',
        ]}
      />
      <ImageBulletSection
        imgSrc={'/service-desc/documents.svg'}
        title={'Podatki, ki jih potrebuje geodet pri zakoličbi objekta'}
        bullets={[
          'Koordinate vogalov objekta ali',
          'razdalje od obstoječih mejnih znamenj in okoliških objektov in',
          'karakteristične višinske kote',
          'karakteristične višinske kote',
          'gradbeno dovoljenje, na podlagi katerega bo izvajal zakoličbo',
        ]}
        bottomText={
          'Ti podatki se nahajajo v vodilni mapi projekta za pridobitev gradbenega dovoljenja (PGD). Najprimerneje je, da projektant, ki je objekt projektiral, geodetskemu podjetju pošlje digitalne podatke o zakoličbi.'
        }
        imgLeft
      />
      <StepsSection
        title={'Kako poteka zakoličba?'}
        subtitle={
          'Postopek poteka v dveh fazah. Najprej geodet izvede parcelacijo, v drugi fazi pa Geodetska uprava RS to parcelacijo evidentira v zemljiški kataster.'
        }
        steps={[
          'Priprava podatkov',
          'Zakoličba na terenu',
          'Skica in zapisnik zakoličbe',
        ]}
      />
      <ServicesExplanationSection
        title="Postopek zakoličbe"
        items={[
          {
            title: 'Priprava podatkov',
            content: (
              <Fragment>
                <p className="mb-10">
                  Pomembno je ali je geodetsko podjetje, ki bo izvajalo
                  zakoličbo, avtor geodetskega načrta, na podlagi katerega je
                  projektant projektiral objekt.
                </p>
                <h4 className="mb-3 font-medium text-base">
                  Geodetsko podjetje je avtor geodetskega načrta
                </h4>
                <p className="mb-10">
                  V tem primeru mora geodet samo prenesti podatke iz vodilne
                  mape v računalniški program oziroma iz digitalnih podatkov, ki
                  mu jih je poslal projektant.
                </p>
                <h4 className="mb-3 font-medium text-base">
                  Geodetsko podjetje ni avtor geodetskega načrta
                </h4>
                <p>
                  V tem primeru je dobro, da geodet poleg podatkov za zakoličbo
                  prejeme tudi geodetski načrt in certifikat geodetskega načrta.
                  V geodetskem načrtu so namreč vrisane poligonske točke, ki so
                  bile uporabljene pri izdelavi geodetskega načrta, v
                  certifikatu pa so njihove koordinate. S temi točkami se geodet
                  postavi v koordinatni sistem geodetskega načrta in bo le tako
                  lahko optimalno zakoličil objekt, ki je bil projektiran.
                  <br />
                  <br />V kolikor teh poligonskih točk ne bo našel, bo moral
                  predhodno na teren, da bo vzpostavil novo poligonsko mrežo.
                </p>
              </Fragment>
            ),
          },
          {
            title: 'Zakoličba na terenu',
            content: (
              <Fragment>
                <p className="mb-10">
                  Geodet se z instrumentom postavi v koordinatni sistem in
                  prične količiti vogale objekta.
                </p>
                <h4 className="mb-3 font-medium text-base">
                  Kako prikaže zakoličeno točko?
                </h4>
                <p className="mb-10">
                  Označitev zakoličbe je odvisna od terena. Običajno so to
                  leseni količki, če pa je podlaga asfaltna pa za jeklene
                  žičnike ali železo-betonsko armaturo.
                </p>
                <h4 className="mb-3 font-medium text-base">
                  Podajanje karakterističnih višin
                </h4>
                <p>
                  Pri stavbah je karakteristična višina t.i. kota 0. To je
                  višina, ki jo je določil projektant in jo geodet označi na
                  terenu. Običajno jo označi na nek fiksen objekt v naravi v
                  neposredni bližini zakoličbe (drevo, ograja, drog ulične
                  luči…).
                  <br />
                  <br />
                  Pri komunalnih vodih - posebej pri kanalizaciji, pa geodet
                  običajno poda višino terena gradbincu, in ta si sam nato
                  preračuna višine jaškov.
                </p>
              </Fragment>
            ),
          },
          {
            title: 'Skica in zapisnik zakoličbe',
            content: (
              <Fragment>
                <p className="mb-10">
                  Po končani terenski zakoličbi, geodet prenese podatke na
                  računalnik in izdela skico ter zapisnik zakoličbe.
                </p>
                <p className="mb-10">
                  Skica vsebuje grafični prikaz zakoličbe in koordinate
                  zakoličenih točk, v zapisniku pa je zapisano na podlagi
                  katerega dokumenta je zakoličba izvedena (gradbeno dovoljenje
                  in številka projekta ter projektant) in izhodišče zakoličbe.
                </p>
                <p>
                  Zapisnik mora biti podpisan s strani:
                  <ul className="list-disc list-inside">
                    <li>odgovornega geodeta,</li>
                    <li>geodetskega podjetja,</li>
                    <li>investitorja gradnje in</li>
                    <li>izvajalca gradbenih del</li>
                  </ul>
                </p>
              </Fragment>
            ),
          },
        ]}
      />
      <div className="mb-16 px-6 container mx-auto md:mb-24 md:px-20">
        <h4 className="font-medium text-xl md:text-2xl md:mb-6 text-neutral/900">
          Zahteva inšpektorata
        </h4>
        <p className="text-base leading-7 text-neutral/700">
          En izvod zakoličbenega zapisnika in skice mora biti na gradbišču ves
          čas gradnje.
        </p>
      </div>
      <ServicesBottom />
    </Fragment>
  );
};

export default Zakolicba;
