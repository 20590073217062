import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

export const ContactCallout: FunctionComponent = () => {
  return (
    <div className="w-full bg-primary/500-p">
      <div className="container mx-auto text-center py-16 px-6 md:p-24">
        <h2 className="text-[32px] leading-10 md:text-[44px] md:leading-[52px] font-light mb-4">
          Potrebujete{' '}
          <span className="font-medium block md:inline-block">izmero?</span>
        </h2>
        <h2 className="text-[32px] leading-10 md:text-[44px] md:leading-[52px] font-light mb-8">
          Mi bomo našli{' '}
          <span className="font-medium block md:inline-block">rešitev.</span>
        </h2>
        <div className="mx-auto font-lexend font-medium text-neutral/900 bg-white max-w-[280px] w-full h-12 flex flex-col justify-center rounded">
          <Link to="/contact">Kontaktirajte nas</Link>
        </div>
      </div>
    </div>
  );
};

export default ContactCallout;
