import axios from 'axios';
import { Fragment, FunctionComponent, useState } from 'react';
import styled from 'styled-components';

export const PersonalDataSection: FunctionComponent = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [legalConfirmed, setLegalConfirmed] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const getSubmitLink = () => {
    const url = window.location.href.includes('localhost')
      ? 'http://localhost:3001'
      : window.location.href.includes('dev')
      ? 'https://dev.center-nepremicnin.si'
      : 'https://www.center-nepremicnin.si';

    return `${url}/api/sendcontactform-geoprojekt?name=${name}&email=${email}&phone=${phone}&msg=${message}`;
  };

  return (
    <Fragment>
      {submitSuccessful ? (
        <div className="mx-auto md:my-20 my-16 md: text-center">
          <h4 className="font-medium text-neutral/900 text-[28px] leading-9 mb-4 md:mb-6">
            Hvala za vaše zanimanje.
          </h4>
          <p className="text-base leading-7">
            Naša ekipa pa vas bo kontaktirala v najkrajšem možnem času.
          </p>
        </div>
      ) : (
        <PersonalDataContainer>
          <Title>Vaši podatki</Title>
          <Label>Ime in priimek*</Label>
          <TextInputField
            placeholder="Vnesite vaše ime in priimek"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Label>Email naslov*</Label>
          <TextInputField
            placeholder="Vnesite vaš email naslov"
            type={'email'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Label>Telefonska številka*</Label>
          <TextInputField
            placeholder="Vnesite vašo telefonsko številko"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Label>Sporočilo*</Label>
          <TextInputArea
            placeholder="Vnesite vaše sporočilo"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <CheckContainer>
            <input
              type={'checkbox'}
              onChange={(e) => setLegalConfirmed(e.target.checked)}
            />
            <CheckLabel>
              Strinjam se, da so zgoraj vpisani osebni podatki posredujejo
              nepremičninski agenciji ali agentu, za potrebe oddanega
              povpraševanja po nepremičnini.
            </CheckLabel>
          </CheckContainer>
          <button
            onClick={() => {
              axios
                .get(getSubmitLink())
                .then(() => setSubmitSuccessful(true))
                .catch(() => {
                  setSubmitError(true);
                  setTimeout(() => setSubmitError(false), 4000);
                });
            }}
            disabled={!legalConfirmed || submitError}
            className="w-full h-12 bg-primary/500-p disabled:bg-[#fbecb5] transition-colors rounded font-lexend font-bold text-base text-neutral/900 mb-16 md:mb-24"
          >
            {submitError ? 'Napaka pri oddaji' : 'Pošlji sporočilo'}
          </button>
        </PersonalDataContainer>
      )}
    </Fragment>
  );
};

const PersonalDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  margin-top: 64px;
`;

const CheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  margin-top: 24px;
  margin-bottom: 32px;
  text-align: left;
  > input {
    margin-top: 4px;
  }
`;

const Title = styled.h2`
  color: #052549;
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  margin-bottom: 0px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const Label = styled.p`
  color: #081130;
  font-size: 12px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 16px;
`;

const CheckLabel = styled.p`
  color: #b0bec5;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0px;
`;

const TextInputField = styled.input`
  width: 100%;
  height: 56px;
  font-weight: 400;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #cfd8dc;
  padding: 0px 16px;
  -webkit-appearance: none;

  ::placeholder {
    color: #b0bec5;
  }
`;

const TextInputArea = styled.textarea`
  width: 100%;
  height: 176px;
  font-weight: 400;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #cfd8dc;
  padding: 8px 16px;
  border: 1px solid #b0bec5;
  -webkit-appearance: none;

  ::placeholder {
    color: #b0bec5;
  }
`;
