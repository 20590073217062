import { Fragment, FunctionComponent } from 'react';

interface Props {
  title: string;
  subtitle?: string;
  steps: string[];
}
export const StepsSection: FunctionComponent<Props> = ({
  title,
  subtitle,
  steps,
}) => {
  return (
    <div className="w-full bg-white">
      <div className="flex flex-col items-center justify-center mx-auto text-center px-6 mb-16 md:py-24">
        <h4 className="font-medium text-2xl md:text-[32px] leading-[34px] md:leading-[40px] mb-6 text-neutral/900">
          {title}
        </h4>
        {subtitle ? (
          <h6 className="text-base leading-7 text-neutral/700 mb-6 md:mb-24 md:max-w-[548px]">
            {subtitle}
          </h6>
        ) : null}
        <div className="max-w-[738px] w-full py-1 pl-10 pr-8 flex flex-col rounded-lg bg-neutral/50 text-left">
          {steps.map((step, i) => (
            <Fragment key={i}>
              <div className="my-8 flex flex-col md:flex-row gap-4 md:gap-6">
                <h4 className=" bg-primary/500-p w-full h-7 max-w-[28px] rounded-full grid justify-center content-center md:rounded md:max-w-[32px] md:h-8">
                  <span className="text-white text-sm md:text-xl">{i + 1}</span>
                </h4>
                <p className="text-base leading-7 text-neutral/700">{step}</p>
              </div>
              {i < steps.length - 1 ? (
                <div className="h-[1px] w-full bg-neutral/200" />
              ) : null}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StepsSection;
