import { Fragment, FunctionComponent } from 'react';
import ConditionsSection from '../../components/ConditionsSection';
import ImageBulletSection from '../../components/ImageBulletSection';
import NavBar from '../../components/NavBar';
import ServiceHero from '../../components/ServiceHero';
import ServicesBottom from '../../components/ServicesBottom';
import ServicesExplanationSection from '../../components/ServicesExplanationSection';
import StepsSection from '../../components/StepsSection';
import WhatIfSection from '../../components/WhatIfSection';

export const Parcelacija: FunctionComponent = () => {
  return (
    <Fragment>
      <NavBar />
      <ServiceHero
        title={'Parcelacija'}
        subtitle={
          'V postopku parcelacije razdelimo eno parcelo na več parcel, ali več parcel združimo v eno.'
        }
      />
      <ImageBulletSection
        imgSrc={'/home/service2.png'}
        title={'Kdaj potrebujete postopek parcelacije?'}
        bullets={[
          'Želite prodati del svoje parcele?',
          'Imate več parcel, pa niso funkcionalne za gradnjo?',
          'Potrebujete parcelacijo za izvedbo OPPN-ja?',
        ]}
      />
      <ConditionsSection
        title={'Pogoji za izvedbo parcelacije'}
        subtitle={'Za izvedbo parcelacije moramo preveriti 4 pogoje.'}
        conditions={[
          {
            img: '/icons/icon1.png',
            text: 'Najprej preverimo ali obstaja za parcele v postopku kakšen prostorski akt, ki definira parcelacije (Občinski Podrobni Prostorski Načrt, Zazidalni Načrt…). Vprimeru, da obstaja, se moramo takšnega prostorskega akta držati.',
          },
          {
            img: '/icons/icon2.png',
            text: 'Drug pogoj pravi, da lahko parcelacijo izvedemo samo na urejenih delih mej. To pomeni, da moramo tam, kjer se nova meja dotika stare, imeti urejeni del meje.',
          },
          {
            img: '/icons/icon3.png',
            text: 'Tretji pogoj velja samo za združitve parcel, pravi pa, da morata parceli ali parcele, ki jih združujemo, imeti enako lastniško in pravno stanje, z izjemo služnosti.',
          },
          {
            img: '/icons/icon4.png',
            text: 'Omejene pa so tudi parcelacije gozdnih parcel. Gozdnih parcel, manjših od 5 ha, namreč ni dovoljeno parcelirati.',
          },
        ]}
      />
      <StepsSection
        title={'Kako poteka postopek ureditve parcelacije?'}
        subtitle={
          'Postopek poteka v dveh fazah. Najprej geodet izvede parcelacijo, v drugi fazi pa Geodetska uprava RS to parcelacijo evidentira v zemljiški kataster.'
        }
        steps={[
          'Geodet izvede parcelacijo.',
          'Geodetska uprava RS to parcelacijo evidentira v zemljiški kataster.',
        ]}
      />
      <ServicesExplanationSection
        title="Geodetovo delo"
        items={[
          {
            title: 'Vabila na meritev',
            content: (
              <p>
                Geodet pisno ali ustno pozove lastnike parcele, ki se bo
                parcelirala, na meritev.
              </p>
            ),
          },
          {
            title: 'Predhodne meritve',
            content: (
              <p>
                Predhodne meritve geodet običajno opravi takrat, ko želijo
                lastniki parcelo, s točno določeno kvadraturo. Primerno je, da
                so lastniki parcele, ki se dali, poleg tudi na predhodnih
                meritvah. Predhodne meritve opravi tudi takrat, ko deli mej niso
                urejeni in se mora v vsakem primeru pripraviti na mejno
                obravnavo.
              </p>
            ),
          },
          {
            title: 'Izvedba parcelacije na terenu',
            content: (
              <p>
                Na terenu geodet po željah stranke ali po načrtu OPPN-ja ali
                ZN-ja izvede parcelacijo in nove parcele oznaži z mejnimi
                znamenji.
              </p>
            ),
          },
          {
            title: 'Izdelava elaborata urejanja mej',
            content: (
              <p>
                Z vsemi podatki, ki jih je geodet zbral na terenu izdela
                elaborat parcelacije. Ključne sestavine elaborata parcelacije
                so:
                <ul className="list-disc list-inside mt-2 mb-4">
                  <li>skica meritve,</li>
                  <li>prikaz sprememb poteka mej,</li>
                  <li>izračun površin parcel</li>
                  <li>
                    navedba dokumenta, na podlagi katerega je bila izvedena
                    parcelacija oziroma navedba, da je bila parcelacija izvedena
                    skladno z željami stranke.
                  </li>
                </ul>
                S tem je zaključeno delo geodeta in z vložitvijo zahtevka za
                evidentiranje parcelacija na Geodetsko upravo RS se prične druga
                faza.
              </p>
            ),
          },
        ]}
      />
      <ServicesExplanationSection
        title="Evidentiranje parcelacije"
        items={[
          {
            title: 'Predaja zahtevka za uvedbo evidentiranja parcelacije',
            content: (
              <p>
                Zahtevek za evidentiranje parcelacije skupaj z elaboratom
                parcelacije, ki ga je izdelal geodet, lastnik preda na Geodetsko
                upravo RS. V kolikor gre za skupno lastnino oziroma solastnino,
                pa morajo zahtevek vložiti vsi skupni lastniki oziroma
                solastniki. Poleg zahtevka in elaborata mora tam plačati še
                upravno takso, ki znaša 22,66 €. To lahko namest njega naredi
                tudi geodet, vendar potrebuje lastnikovo pooblastilo, takso pa
                doda kot strošek na računu za storitev parcelacije.
              </p>
            ),
          },
          {
            title: 'Evidentiranje parcelacija',
            content: (
              <p>
                Geodetska uprava RS pregleda elaborat in preveri, da so meje,
                kjer se parcelacija dotika starih mej, urejene, ali je bila
                parcelacija narejena skladno s prostorskim aktom in ali so
                kakšni drugi zadržki za parcelacijo. Če teh ni o zahtevi za
                evidentiranje parcelacije odloči z odločbo, s katero se nove
                parcele vpišejo v zemljiški kataster, hkrati pa se parcelne meje
                določijo kot urejeni deli mej.
              </p>
            ),
          },
        ]}
      />
      <WhatIfSection
        hideSubtitle
        items={[
          {
            title:
              'Meje parcel, kjer se parcelacija dotika starih mej parcele, niso urejene.',
            text: 'To je običajna praksa, saj si nihče ne da urediti meje, če to ni nujno potrebno. V tem primeru, bo geodet najprej uredil mejo in še v istem postopku napravil parcelacijo. V pisarni bo izdelal enoten elaborat urejanja mej in parcelacije. Prav tako bo zahteva na Geodetski upravi RS enojna za evidentiranje urejene mej in parcelavije.',
          },
          {
            title: 'Želite združiti dve parceli pa pravno stanje ni enako.',
            text: 'Žal takšne združitve ne boste mogli opraviti.',
          },
          {
            title:
              'Imate gozdno parcelo manjšo od 5 ha, pa jo želite del prodati sosedu?',
            text: 'Edina možnost, ki se tu pojavlja je izravnava s sosednjo parcelo. Žal ta opcija ne pride v poštev za osebe, katerih parcela se ne dotika vaše.',
          },
        ]}
      />
      <ServicesBottom />
    </Fragment>
  );
};

export default Parcelacija;
