import React, { FunctionComponent } from 'react';

interface Props {
  children?: React.ReactNode;
  bgColor?: string;
  title?: string;
  tagline?: string;
  description?: string;
  fullWidth?: boolean;
}
export const Section: FunctionComponent<Props> = ({
  children,
  bgColor = 'bg-white',
  title,
  tagline,
  description,
  fullWidth,
}) => {
  return (
    <div className={`w-full ${bgColor}`}>
      <div
        className={`${
          fullWidth ? '' : 'container px-6'
        } mx-auto text-center py-16`}
      >
        <div className="flex flex-col items-center justify-center mb-16">
          <h5 className="font-medium text-sm leading-7 tracking-[1.5px] mb-6 text-neutral/800-p">
            {title}
          </h5>
          <h3 className="font-medium text-[32px] md:text-[44px] leading-[40px] md:leading-[52px] mb-8 text-neutral/900">
            {tagline}
          </h3>
          {description ? (
            <h6 className="text-base leading-7 text-neutral/700">
              {description}
            </h6>
          ) : null}
        </div>
        {children}
      </div>
    </div>
  );
};

export default Section;
