import { FunctionComponent } from 'react';

export const Hero: FunctionComponent = () => {
  return (
    <div className="w-full h-[560px] md:h-[720px] text-center flex justify-center items-center bg-neutral/900 relative overflow-hidden">
      <div className="absolute">
        <img
          src="/home/dots.png"
          alt="Dots hero"
          className="relative w-[212px] -top-12 -right-36 md:right-80 md:top-56 md:w-full"
        />
        <img
          src="/home/dots.png"
          alt="Dots hero"
          className="relative w-[212px] -bottom-20 -left-36 md:left-80 md:bottom-48 md:w-full"
        />
      </div>
      <div className="flex flex-col items-center">
        <h1 className="text-4xl leading-[44px] md:text-6xl md:leading-[72px] font-medium md:font-bold md:w-[490px] text-white">
          Ko potrebujete
        </h1>
        <h1 className="text-4xl leading-[44px] md:text-6xl md:leading-[72px] font-medium md:font-bold mb-8 md:w-[530px] text-primary/500-p">
          več kot geodeta.
        </h1>
        <h5 className="text-base leading-7 md:w-[440px] text-[#E0E0E0]">
          Podjetje z 31 letno tradicijo, specializirano za geodetsko podporo
          kupcem in prodajalcem nepremičnin, arhitektom, investitorjem in
          nepremičninskim agencijam.
        </h5>
      </div>
    </div>
  );
};

export default Hero;
