import { Link } from 'react-router-dom';

interface Props {
  icon: string;
  title: string;
  subtitle: string;
  route: string;
}

const ClientCard: React.FC<Props> = ({ icon, title, subtitle, route }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-6 bg-neutral/50 px-7 py-8 max-w-sm h-116 md:gap-10 md:py-14">
      <div className="h-16 w-16">
        <img src={icon} alt="house" />
      </div>
      <div>
        <h4 className="font-medium text-2xl text-neutral/900 mb-2 md:mb-4">
          {title}
        </h4>
        <h5 className="text-base leading-7 text-neutral/700">{subtitle}</h5>
      </div>
      <Link
        to={route}
        className="mt-auto font-lexend text-white bg-primary/500-p w-full h-12 flex flex-col justify-center rounded md:w-[168px] md:mb-"
      >
        Več
      </Link>
    </div>
  );
};

export default ClientCard;
