import { FunctionComponent } from 'react';

export const CustomIconTextSection: FunctionComponent = () => {
  return (
    <div className="h-full py-16 px-6 mb-16 md:mb-0 md:px-20 md:py-24 bg-neutral/50">
      <div className="container mx-auto flex flex-col md:flex-row gap-10">
        <div className="md:flex-1 md:px-10">
          <img src="/icons/document.svg" className="w-20 h-20 mb-6 md:mb-20" />
          <h4 className="mb-5 md:mb-6 text-xl leading-[26px] font-medium text-neutral/900">
            Podatki, ki jih mora naročnik geodetskega načrta posredovati geodetu
          </h4>
          <p className="mb-4">
            Pri naročilu mora naročnik geodetskega načrta geodetu povedati:
          </p>
          <ul className="list-disc list-outside ml-6 text-base leading-7 text-neutral/700">
            <li className="p-1.5">za kaj bo potreboval geodetski načrt</li>
            <li className="p-1.5">za katere parcele ga bo potreboval</li>
            <li className="p-1.5">
              v kateri katastrski občini se te parcele nahajajo
            </li>
          </ul>
        </div>

        <div className="md:flex-1 md:px-10">
          <img src="/icons/calendar.svg" className="w-20 h-20 mb-6 md:mb-20" />
          <h4 className="mb-5 md:mb-6 text-xl leading-[26px] font-medium text-neutral/900">
            Dogovor za termin terenske izmere
          </h4>
          <p className="mb-4">
            Geodet se z naročnikom dogovori, kdaj bo izvedel meritve. Ta dogovor
            je pomemben zato, ker mora naročnik geodetu povedati:
          </p>
          <ul className="list-disc list-outside ml-6 text-base leading-7 text-neutral/700">
            <li className="p-1.5">
              kje bo stal novozgrajeni objekt in kako velik bo
            </li>
            <li className="p-1.5">kje na parceli so vidna mejna znamenja</li>
            <li className="p-1.5">
              kje potekajo obstoječi infrastrukturni vodi (elektrika, vodovod,
              kanalizacija, telefon…),
            </li>
            <li className="p-1.5">
              kje se bo nov objekt priključeval na infrastrukturo
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CustomIconTextSection;
