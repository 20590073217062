import { FunctionComponent, useState } from 'react';
import SelectSidebar from '../../components/SelectSidebar';
import ServiceInfo from '../../components/ServiceInfo';
import { services } from '../../Helpers';

export const ServicePresentationSection: FunctionComponent = () => {
  const [selectedService, setSelectedService] = useState<number>(0);

  // TODO: Make responsive
  return (
    <div className="mx-auto flex items-stretch justify-between md:gap-16 lg:gap-24">
      <SelectSidebar
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
      <img
        src={`/home/${services[selectedService].img}`}
        className="self-center max-w-[358px] max-h-[358px] min-w-0 h-auto w-full object-cover transition-all duration-300"
      />
      <ServiceInfo service={services[selectedService]} />
    </div>
  );
};

export default ServicePresentationSection;
