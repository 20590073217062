import { FunctionComponent } from 'react';
import ClientCard from '../../components/ClientCard';
import Section from '../../components/Section';

export const ClientsSection: FunctionComponent = () => {
  return (
    <Section
      title="NAŠE STRANKE"
      tagline="Pripravljeni na izzive
  vseh lastnikov nepremičnin"
    >
      <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
        <ClientCard
          title="Lastniki nepremičnin"
          subtitle="Lastniki, prodajalci, upravniki, lokalne skupnosti"
          icon="/icons/house.png"
          route="/lastniki-nepremicnin"
        />
        <ClientCard
          title="Kupci in graditelji"
          subtitle="Kupci nepremičnin, graditelji, investitorji nepremičninskih
          projektov za trg."
          icon="/icons/builders.png"
          route="/kupci-in-graditelji"
        />
        <ClientCard
          title="Agenti in investitorji"
          subtitle="Pridobite najboljše in preverjene kupce ter sklenite posel brez
          tveganj."
          icon="/icons/agents.png"
          route="/agenti-in-investitorji"
        />
      </div>
    </Section>
  );
};

export default ClientsSection;
