import { FunctionComponent } from 'react';

interface Props {
  title: string;
  subtitle: string;
}
export const ServiceHero: FunctionComponent<Props> = ({ title, subtitle }) => {
  return (
    <div className="w-full px-6 py-16 md:p-24 text-center bg-neutral/900">
      <h1 className="text-4xl leading-[44px] md:text-6xl md:leading-[72px] mx-auto mb-8 font-medium text-white">
        {title}
      </h1>
      <h5 className="max-w-[438px] text-base leading-7 text-neutral/300 mx-auto">
        {subtitle}
      </h5>
    </div>
  );
};

export default ServiceHero;
